import styled from 'styled-components';

export const Container = styled.section`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-top: 54px;
  padding-bottom: 5%;
  background: #fff;
  #box-resume {
    max-width: 1200px;
  }
  h1 {
    color: '#23f9a';
    width: 100%;
    text-align: center;
    text-transform: uppercase;
  }
  h3 {
    width: 100%;
    text-align: center;
    color: #777;
  }
  #resumoBox {
    max-width: 1200px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5px;
    grid-template-rows: 375px;
    margin: 0;
    text-align: center;
  }
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  h4 {
    margin-top: 2%;
  }
  p {
    margin-top: 2%;
    width: 80%;
    color: #777;
  }
  @media (max-width: 768px) {
    width: 100%;
    padding: 20px 0;
  }
`;
