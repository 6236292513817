import React from 'react';

import { Container } from './styles';

export default function Footer() {
  return (
    <Container>
      <p>Moreira Digital © 2020 - Todos os direitos reservados</p>
    </Container>
  );
}
