import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0 auto;
  height: 100px;
  align-items: center;
  justify-content: space-around;
  background: #fff;
  position: fixed;
  z-index: 9;
  border-bottom: solid 1px #f5f5f5;
  img {
    width: 100px;
    height: 100px;
  }
  div {
    width: 10%;
  }
  button {
    text-align: left;
    border: none;
    background: none;
    padding: 0;
    margin: 0;
  }
  @media (min-width: 769px) {
    display: none;
  }
`;
export const Menu = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 9;
  top: 100px;
  width: 100%;
  height: 100%;
  background: #fff;
  transition: margin 0.5s;
  margin: 0 0 0 -768px;
  &.active {
    margin-left: 0;
  }
  nav {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  a {
    width: 100%;
    height: 93px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 10%;
    color: #023f9a;
    border-bottom: solid 1px #f5f5f5;
  }
  a.active {
    color: #fff;
    background: #023f9a;
  }
  @media (min-width: 768px) {
    display: none;
  }
`;
