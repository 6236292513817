import React from 'react';

import { Container, Box } from './styles';
import gypsum1 from '../../assets/portfolio/gypsum/gypsum-2.png';
import bariatrica1 from '../../assets/portfolio/bariatrica/bariatrica-2.png';
import catalogo1 from '../../assets/portfolio/sandoz/catalogo.png';
import yogin1 from '../../assets/portfolio/yogin/1.png';

export default function Work() {
  return (
    <Container name="work">
      <div id="box-work">
        <h1>PORTFÓLIO</h1>
        <h3>Alguns dos meus trabalhos.</h3>
        <Box>
          <img src={gypsum1} alt="Gypsum" />
          <h4>
            Aplicativo de cálculos para montagem de paredes, forros ou
            revestimentos. Disponível IOS e Android
          </h4>
          <p>Tecnologia: React Native e Node JS</p>
        </Box>
        <Box>
          <img src={bariatrica1} alt="Bariátrica" />
          <h4>
            Aplicativo para acompanhamento da cirugia bariátrica: consultas,
            exames e medicamentos. Disponível IOS e Android
          </h4>
          <p>Tecnologia: React Native e Node JS</p>
        </Box>
        <Box>
          <img
            className="site"
            src={catalogo1}
            alt="Catálogo Sandoz"
            width="512"
            height="385"
          />
          <h4>Catálogo Sandoz - Site e Ipad</h4>
          <p>Tecnologia: React JS e Swift </p>
        </Box>
        <Box>
          <img src={yogin1} alt="YogIN App" />
          <h4>Aplicativo para praticantes de yoga IOS e Android</h4>
          <p>Tecnologia: Swift e Ionic </p>
        </Box>
      </div>
    </Container>
  );
}
