import React from 'react';
import { Link } from 'react-scroll';
import './styles.css';
import logo from '../../assets/logo-2.png';
function Header() {
  return (
    <div id="menu">
      <div id="menu-content">
        <nav>
          <div id="menu-img-logo">
            <Link
              to="home"
              spy={true}
              smooth={true}
              offset={-100}
              duration={700}
            >
              <img src={logo} alt="logo"></img>
            </Link>
          </div>
          <div id="menu-link">
            <Link
              to="resume"
              spy={true}
              smooth={true}
              offset={-99}
              duration={700}
            >
              O que eu faço
            </Link>

            <Link
              to="work"
              spy={true}
              smooth={true}
              offset={-99}
              duration={700}
            >
              Portfólio
            </Link>

            <Link
              to="about"
              spy={true}
              smooth={true}
              offset={-99}
              duration={700}
            >
              Sobre
            </Link>

            <Link
              to="contact_view"
              spy={true}
              smooth={true}
              offset={-99}
              duration={700}
            >
              Contato
            </Link>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default Header;
