import styled from 'styled-components';

export const Container = styled.section`
  background-image: linear-gradient(#38c4f7, #5369be);
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 54px;
  padding-bottom: 5%;
  h1 {
    color: '#23f9a';
    width: 100%;
    text-align: center;
    text-transform: uppercase;
  }
  h3 {
    width: 100%;
    text-align: center;
    color: #fff;
    margin-top: 1%;
  }
  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    margin: 2% 0;
    width: 40%;
  }
  p {
    margin-bottom: 10%;
    color: #023f9a;
  }
  p.center {
    text-align: center;
  }
  input,
  textarea {
    width: 100%;
    height: auto;
    border-radius: 4px;
    height: 44px;
    border: none;
    padding: 10px 20px;
    color: #023f9a;
    font-size: 1.2em;
    margin-top: 1%;
  }
  textarea {
    height: 120px;
  }
  button {
    background-color: #023f9a;
    border-color: #023f9a;
    padding: 10px 40px;
    font-weight: 700;
    font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    text-transform: uppercase;
    font-size: 16px;
    border-radius: 3px;
    color: #fff;
  }
  @media (max-width: 768px) {
    form {
      width: 90%;
    }
  }
`;
