import styled from 'styled-components';

export const Container = styled.section`
  background: #fff;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 54px;
  padding-bottom: 5%;
  #box_about {
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  img.perfil {
    width: 25%;
    height: 25%;
    border-radius: 50%;
    margin: 5% 0;
  }
  p {
    width: 90%;
    margin-top: 2%;
  }
  h1 {
    color: '#23f9a';
    width: 100%;
    text-align: center;
    text-transform: uppercase;
  }
  h3 {
    width: 100%;
    text-align: center;
    color: #777;
  }
  h2 {
    width: 100%;
    text-align: center;
    margin-top: 5%;
    color: #023f9a;
  }
  h3.opt2 {
    margin: 2% 0;
    font-size: 1.5em;
  }

  @media (max-width: 768px) {
    h2 {
      margin-top: 20%;
    }
  }
`;

export const BoxSkill = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  img {
    width: 74px;
    height: 64px;
    margin: 2.5%;
  }
  @media (max-width: 768px) {
    width: 90%;
  }
`;
