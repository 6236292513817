import React, { useEffect } from 'react';
import { faWhatsapp, faGithub } from '@fortawesome/free-brands-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Container, BoxPresentation } from './styles';
import { Link } from 'react-scroll';

import Typical from 'react-typical';

let txts = [
  'Front-end com ReactJS',
  'Back-end com Node.js',
  'Mobile com React Native',
];
let speed = 50;

export default function Home() {
  let line;
  useEffect(() => {
    line = document.getElementById('line');
    writeLoop();
  }, []);

  async function typewriter(txt) {
    for (let i = 0; i < txt.length; i++) {
      line.innerHTML += txt.charAt(i);
      await delay(speed);
    }
  }

  async function reverseTypewriter(txt) {
    for (let i = txt.length; i > 0; i--) {
      line.innerHTML = line.innerHTML.slice(0, -1);
      await delay(speed);
    }
  }

  async function writeLoop() {
    for (let i = 0; i < txts.length; i++) {
      await typewriter(txts[i]);
      await delay(1000);
      await reverseTypewriter(txts[i]);
      await delay(1000);
    }

    writeLoop();
  }

  function delay(ms) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve();
      }, ms);
    });
  }

  return (
    <Container name="home">
      <BoxPresentation>
        <h1>ADRIANO MOREIRA</h1>
        <h2>Desenvolvedor de aplicativos e sites:</h2>
        <div className="boxCursor">
          <h2 id="line"></h2>
          <div className="cursor"></div>
        </div>

        <div id="first-contato">
          <a
            href="https://wa.me/5511942730850?text=Oi%20Adriano%20"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon
              icon={faWhatsapp}
              color="#023f9a"
              size="8x"
              transform="shrink-8"
            />
            <span>whatsapp</span>
          </a>
          <a
            href="https://github.com/adrianodesenv"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon
              icon={faGithub}
              color="#023f9a"
              size="8x"
              transform="shrink-8"
            />
            <span>github</span>
          </a>
        </div>
      </BoxPresentation>
      <Link
        to="resume"
        spy={true}
        smooth={true}
        offset={-99}
        duration={700}
        className="goResume"
      >
        SAIBA MAIS
      </Link>
    </Container>
  );
}
