import styled from 'styled-components';

export const Container = styled.section`
  width: 100%;
  background-image: radial-gradient(circle, #5369be 0%, #023f9a 100%);
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  a.goResume {
    background: #38c4f7;
    text-decoration: none;
    letter-spacing: 0;
    color: #fff;
    padding: 12px 20px;
    border: none;
    cursor: pointer;
    max-width: 350px;
    border-radius: 1.5em;
    margin-top: 20px;
  }
`;
export const BoxContato = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 20%;
`;
export const BoxPresentation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding-bottom: 30px;
  padding-top: 30px;
  text-align: center;
  border-radius: 2em;
  position: relative;
  height: 40%;
  h1 {
    color: #fff;
    font-size: 2.2rem;
    text-transform: uppercase;
  }
  h2 {
    color: #fff;
    padding: 10px 0;
    width: 100%;
  }
  .boxCursor {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 27px;
  }

  #first-contato {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #first-contato a {
    display: flex;
    flex-direction: column;
    text-decoration: none;
  }
  #first-contato a span {
    color: #fff;
    opacity: 0;
    margin-top: -30px;
    transition: opacity 0.5s ease-out;
  }
  #first-contato a:hover span {
    opacity: 1;
  }
  .cursor {
    height: 2rem;
    width: 2px;
    margin-left: 2px;
    background: #fff;
    animation: blinkTextCursor 800ms infinite;
  }

  @keyframes blinkTextCursor {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  @media (max-width: 768px) {
    width: 90%;
    height: 70vh;
  }
`;
