import React, { useState } from 'react';

import { Container } from './styles';

export default function Contact() {
  const [name, setName] = useState('');
  const [status, setStatus] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [subject, setSubject] = useState('');

  const encode = data => {
    const formData = new FormData();
    Object.keys(data).forEach(k => {
      formData.append(k, data[k]);
    });
    return formData;
  };

  const handleSubmit = e => {
    const data = { 'form-name': 'contact', name, email, message, subject };

    fetch('/', {
      method: 'POST',
      // headers: { "Content-Type": 'multipart/form-data; boundary=random' },
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode(data),
    })
      .then(() => setStatus('Formulário enviado com sucesso!!'))
      .catch(error => setStatus('Formulário envio Falhou!'));

    e.preventDefault();
  };
  const handleChange = e => {
    const { name, value } = e.target;
    if (name === 'name') {
      return setName(value);
    }
    if (name === 'email') {
      return setEmail(value);
    }
    if (name === 'message') {
      return setMessage(value);
    }
    if (name === 'subject') {
      return setSubject(value);
    }
  };

  return (
    <Container name="contact_view">
      <h1>Contate-me</h1>
      <h3>Eu adoraria ouvir você.</h3>
      <form onSubmit={handleSubmit} action="/" data-netlify="true">
        <p>
          <label>
            Seu Nome:{' '}
            <input
              type="text"
              name="name"
              value={name}
              onChange={handleChange}
            />
          </label>
        </p>
        <p>
          <label>
            Seu Email:{' '}
            <input
              type="email"
              name="email"
              value={email}
              onChange={handleChange}
            />
          </label>
        </p>
        <p>
          <label>
            Assunto:{' '}
            <input
              type="text"
              name="subject"
              value={subject}
              onChange={handleChange}
            />
          </label>
        </p>
        <p>
          <label>
            Mensagem:{' '}
            <textarea
              name="message"
              value={message}
              onChange={handleChange}
            ></textarea>
          </label>
        </p>
        <p className="center">
          <button type="submit">Enviar</button>
        </p>
      </form>
      <h3>{status}</h3>
    </Container>
  );
}
