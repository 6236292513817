import styled from 'styled-components';

export const Container = styled.section`
  background: #ebeeee;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  padding-top: 54px;
  padding-bottom: 5%;
  #box-work {
    max-width: 1200px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
  }
  h1 {
    color: '#23f9a';
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    margin-top: 54px;
  }
  h3 {
    width: 100%;
    text-align: center;
    color: #777;
  }
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 40%;
  padding: 2%;
  margin-top: 2%;
  background: #f5f5f5;
  h4 {
    margin-top: 2%;
    width: 100%;
  }
  p {
    margin-top: 2%;
    width: 100%;
    color: #000;
  }
  img {
    width: 205px;
    height: 417px;
  }
  img.site {
    width: 384px;
    height: 288px;
  }
  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
    padding: 20px 0;
    img {
      width: 102px;
      height: 208px;
    }
    img.site {
      width: 256px;
      height: 197px;
    }
    h4 {
      width: 90%;
      text-align: center;
    }
    p {
      width: 90%;
      text-align: center;
    }
  }
`;
