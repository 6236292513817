import React from 'react';

import { Container, BoxSkill } from './styles';

import perfil from '../../assets/perfil.jpg';

import adonis from '../../assets/skill/adonis.jpg';
import bootstrap from '../../assets/skill/bootstrap.png';
import css from '../../assets/skill/css.png';
import docker from '../../assets/skill/docker.png';
import express from '../../assets/skill/express.png';
import html from '../../assets/skill/html.png';
import js from '../../assets/skill/js.png';
import mongodb from '../../assets/skill/mongodb.jpg';
import mysql from '../../assets/skill/mysql.jpg';
import nodejs from '../../assets/skill/nodejs.png';
import postgres from '../../assets/skill/postgres.png';
import reactjs from '../../assets/skill/reactjs.png';
import reactnative from '../../assets/skill/reactnative.png';
import redux from '../../assets/skill/redux.png';

export default function About() {
  return (
    <Container name="about">
      <div id="box_about">
        <h1>SOBRE MIM</h1>
        <h3>Saiba um pouco mais</h3>
        <img className="perfil" src={perfil} alt="perfil" />
        <h1>Adriano Moreira</h1>
        <h4>React | React Native | Node JS</h4>
        <p>
          Sou desenvolvedor desde 2008, comecei em uma consolidadora de bilhetes
          aéreos que atende exclusivamente aos agentes de viagens, participei de
          diversos projetos internos criando o back-end com a linguagem c# e o
          banco SQL.
        </p>
        <p>
          Logo após, iniciei uma jornada em uma agência de publicidade no ramo
          Health Care e lá trabalhei com outras linguagens: Swift, JavaScript e
          PHP, criando diversos aplicativos (principalmente para o IOS) para os
          principais laboratórios como: Bayer, Roche, Pfizer, Sandoz entre
          outros.
        </p>
        <p>
          Atualmente estou trabalhando com o ecossistema Node JS, React JS,
          React Native, que utiliza a linguagem javascript, que vem crescendo
          muito nos últimos anos e vem a facilitar na criação e manutenção de
          aplicativos.
        </p>
        <h3 className="opt2">
          Abaixo, você encontrará uma lista de algumas das minhas skills:
        </h3>

        <h2>Back-end</h2>
        <BoxSkill>
          <img src={adonis} alt="adonis" />
          <img src={docker} alt="docker" />
          <img src={express} alt="express" />
          <img src={js} alt="JS" />
          <img src={mongodb} alt="mongodb" />

          <img src={nodejs} alt="nodejs" />
        </BoxSkill>

        <h2>Banco de dados</h2>
        <BoxSkill>
          <img src={mysql} alt="mysql" />
          <img src={postgres} alt="postgres" />
        </BoxSkill>
        <h2>Front-end</h2>

        <BoxSkill>
          <img src={bootstrap} alt="bootstrap" />
          <img src={css} alt="css" />
          <img src={html} alt="html" />
          <img src={js} alt="JS" />
          <img src={reactjs} alt="reactjs" />
          <img src={reactnative} alt="reactnative" />
          <img src={redux} alt="redux" />
        </BoxSkill>
      </div>
    </Container>
  );
}
