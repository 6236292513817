import React from 'react';
import {
  faCircle,
  faCode,
  faMobile,
  faCogs,
  faDatabase,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Container, Box } from './styles';

export default function Resume() {
  return (
    <Container name="resume">
      <div id="box-resume">
        <h1>O que eu faço</h1>
        <h3>Coisas pelas quais sou hábil e apaixonado.</h3>
        <div id="resumoBox">
          <Box>
            <FontAwesomeIcon
              mask={faCircle}
              icon={faCode}
              color="#023f9a"
              size="8x"
              transform="shrink-8"
            />
            <h4>Desenvolvimento Aplicação Web </h4>
            <p>
              Aplicativos rápidos, responsivos e envolventes que dão vida às
              suas idéias.
            </p>
          </Box>
          <Box>
            <FontAwesomeIcon
              mask={faCircle}
              icon={faMobile}
              color="#023f9a"
              size="8x"
              transform="shrink-8"
            />

            <h4>Desenvolvimento Aplicação móvel</h4>
            <p>
              Aplicativos criados com eficiência e velocidade para Android e iOS
              de uma só vez.
            </p>
          </Box>
          <Box>
            <FontAwesomeIcon
              mask={faCircle}
              icon={faCogs}
              color="#023f9a"
              size="8x"
              transform="shrink-8"
            />

            <h4>Desenvolvimento e Integração de API</h4>
            <p>Integração da API REST</p>
            <p>Desenvolvimento da API REST com Node.js</p>
          </Box>
          <Box>
            <FontAwesomeIcon
              mask={faCircle}
              icon={faDatabase}
              color="#023f9a"
              size="8x"
              transform="shrink-8"
            />

            <h4>Design do Banco de Dados </h4>
            <p>
              Arquiteturas de banco de dados em MongoDB, MySQL ou Postgres,
              sempre visando desempenho, escala e estabilidade.
            </p>
          </Box>
        </div>
      </div>
    </Container>
  );
}
