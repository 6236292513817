import React, { useState, useEffect } from 'react';

import MenuMobile from './pages/MenuMobile';
import Header from './pages/Header';
import Home from './pages/Home';
import Resume from './pages/Resume';
import Work from './pages/Work';
import About from './pages/About';
import Contact from './pages/Contact';
import Footer from './pages/Footer';

import GlobalStyles from './styles/global';

import { Helmet } from 'react-helmet';

function App() {
  const [is1, setI1] = useState(true);

  const seo = {
    title: 'Moreira Digital',
    description:
      'Desenvolvedor aplicativos e sites na stack Node JS, React JS e React Native',
    url: 'https://moreiradigital.com.br/',
    image: 'https://moreiradigital/public/image.png',
  };

  function handleScroll() {
    const y = window.pageYOffset;
    if (
      y > 105 &&
      y < document.getElementsByName('home')[0].offsetHeight - 105
    ) {
      setI1(false);
    } else {
      setI1(true);
      if (y > 105) {
        document.getElementById('menu').classList.add('active');
      } else {
        //document.getElementById('menu').classList.remove('active');
      }
    }
  }
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  return (
    <>
      <MenuMobile id="menuMobile"></MenuMobile>
      {is1 ? <Header id="menuDesktop"></Header> : null}
      <Home></Home>
      <Resume></Resume>
      <Work></Work>
      <About></About>
      <Contact></Contact>
      <Footer></Footer>
      <GlobalStyles></GlobalStyles>
      <Helmet
        title={seo.title}
        meta={[
          {
            name: 'description',
            property: 'og:description',
            content: seo.description,
          },
          { property: 'og:title', content: seo.title },
          { property: 'og:url', content: seo.url },
          { property: 'og:image', content: seo.image },
          { property: 'og:image:type', content: 'image/png' },
          { property: 'twitter:image:src', content: seo.image },
          { property: 'twitter:title', content: seo.title },
          { property: 'twitter:description', content: seo.description },
        ]}
      />
    </>
  );
}

export default App;
