import styled from 'styled-components';

export const Container = styled.footer`
  padding: 2.5% 0;
  color: #fff;
  font-size: 14px;
  text-align: center;
  background: #5369be;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    width: 100%;
  }
  @media (max-width: 768px) {
    width: 90%;
  }
`;
