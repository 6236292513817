import { createGlobalStyle } from 'styled-components';

export const Global = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline:0;
    font-family: 'Montserrat', sans-serif;
  }
  html,body, #root{
    min-height:100%;
    background: #023f9a;
  }
  body{
    text-rendering: optimizelegibility !important;
    -webkit-font-smoothing: antialiased !important;
  }
  :root {

      font-size: 18px;
      h3{
        font-family: 'Montserrat', cursive;
      }

      a,p{
        font-size: 18px;
      }
      @media (min-width: 768px) {
        font-size: 16px;
        a,p{
        font-size: 16px;
        }
      }

      @media (min-width: 1024px) {
        font-size: 20px;
        a,p{
        font-size: 20px;
        }
      }
    }
`;

export default Global;
