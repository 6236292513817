import React, { useState } from 'react';

import { Container, Menu } from './styles';
import { Link } from 'react-scroll';

import logo from '../../assets/logo.png';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function MenuMobile() {
  const [showMenu, setShowMenu] = useState(true);
  function handleMenu() {
    //setShowMenu(!showMenu);
    document.getElementById('listMenuMobile').classList.toggle('active');
  }

  function closeMenu() {
    document.getElementById('listMenuMobile').classList.remove('active');
  }
  return (
    <>
      <Container>
        <button type="button" onClick={handleMenu}>
          <FontAwesomeIcon
            icon={faBars}
            color="#023f9a"
            size="5x"
            transform="shrink-8"
          />
        </button>

        <img src={logo} alt="logo"></img>
        <div></div>
      </Container>
      {showMenu ? (
        <Menu id="listMenuMobile">
          <nav>
            <Link
              className="name"
              to="home"
              spy={true}
              smooth={true}
              offset={-100}
              duration={10}
              onClick={closeMenu}
            >
              Adriano Moreira
            </Link>

            <Link
              to="resume"
              spy={true}
              smooth={true}
              offset={-99}
              duration={10}
              onClick={closeMenu}
            >
              O que eu faço
            </Link>

            <Link
              to="work"
              spy={true}
              smooth={true}
              offset={-99}
              duration={10}
              onClick={closeMenu}
            >
              Portfólio
            </Link>

            <Link
              to="about"
              spy={true}
              smooth={true}
              offset={-99}
              duration={10}
              onClick={closeMenu}
            >
              Sobre
            </Link>

            <Link
              to="contact_view"
              spy={true}
              smooth={true}
              offset={-99}
              duration={10}
              onClick={closeMenu}
            >
              Contato
            </Link>
          </nav>
        </Menu>
      ) : null}
    </>
  );
}
